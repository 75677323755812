import React from "react"
import { Field } from "formik"

export const CountryDropdownField = ({
  name = "country",
  isRequired = false,
  disabled,
  ...props
}) => {
  return (
    <Field
      as="select"
      name={name}
      validate={(value) =>
        isRequired ? (value ? undefined : "Land is verplicht") : undefined
      }
      style={{
        margin: 0,
        width: "100%",
        boxSizing: "border-box",
        borderRadius: "10px",
        border: "1px solid #d9d9d9",
        height: "40px",
        alignSelf: "stretch",
        paddingLeft: "10px",
        fontSize: "14px !important",
        fontFamily: '"Quicksand", sans-serif, Arial, Helvetica',
        fontWeight: 400,
      }}
      disabled={disabled}
      {...props}
    >
      <option value="NL">Nederland</option>
      <option value="BE">België</option>
    </Field>
  )
}
