import React from "react"
import {
  modalWrapper,
  modalContent,
  modalTitle,
  modalContainer,
  modalTitleContainer,
  modalClose,
} from "./modal-wrapper.module.css"
import { MdClose } from "react-icons/md"

const ModalWrapper = ({ children, title, showModal, setShowModal }) => {
  if (!showModal) return null
  return (
    <div className={modalWrapper}>
      <div className={modalContainer}>
        <div className={`${modalContent} slide-in`}>
          {title && (
            <div className={modalTitleContainer}>
              <h2 className={modalTitle}>{title}</h2>
              <MdClose
                className={modalClose}
                size="32"
                onClick={() => setShowModal(false)}
              />
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  )
}

export default ModalWrapper
