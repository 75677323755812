import firebase from "gatsby-plugin-firebase"

export const onFormBuilderSubmit = async (contentfulProductId, formData, formType) => {
  const cf_onFormBuilderSubmit = firebase
    .app()
    .functions("europe-west1")
    .httpsCallable("formBuilder-onFormBuilderSubmit")

  try {
    const result = await cf_onFormBuilderSubmit({ contentfulProductId, formData, formType })
    return result.data
  } catch (error) {
    console.error("Error creating submission:", error)
    throw error
  }
}
