import React, { useState } from "react"

const CandidateAccordionItem = ({ title, children }) => {
  const [open, setOpen] = useState(true)

  return (
    <div style={{ paddingTop: "24px", borderTop: "1px solid lightgray" }}>
      <dt style={{ fontSize: "18px", lineHeight: "28px" }}>
        <button
          onClick={() => setOpen((prevState) => !prevState)}
          type="button"
          style={{
            textAlign: "left",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            color: "#9ca3af",
            border: "none",
            background: "none",
          }}
        >
          <span style={{ fontWeight: "500", color: "#111827" }}>{title}</span>
          <span
            style={{
              marginLeft: "24px",
              height: "28px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={open ? "-rotate-180" : "rotate-0"}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
          </span>
        </button>
      </dt>
      <dd
        style={{
          display: open ? "block" : "none",
          marginTop: "8px",
          marginInlineStart: "0px",
        }}
      >
        <p
          style={{
            fontSize: "16px",
            lineHeight: "24px",
            color: "#6b7280",
          }}
        >
          {children}
        </p>
      </dd>
    </div>
  )
}

export default CandidateAccordionItem
